.react-datepicker {
    border-radius: 0 !important;
    font-family: 'Open Sans', sans-serif !important;
    font-size: 14px !important;
    margin-top: -10px !important;

    &__triangle {
        display: none;
    }

    &__day:hover,
    &__day--selected,
    &__day--keyboard-selected {
        border-radius: 50% !important;
    }

    &__day--selected,
    &__day--keyboard-selected {
        background-color: #0c8a59 !important;

        &:hover {
            background-color: #0a754c !important;
        }
    }

    &-popper {
        width: calc(100% - 24px);
    }
}