$font-family-sans-serif: 'Open Sans', sans-serif;
$body-color: #333;

$font-size-base: 1rem; // Assumes the browser default, typically `16px`

$h1-font-size: $font-size-base * 2.8;
$h2-font-size: $font-size-base * 1.6;
$h3-font-size: 26px;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: 18px;
$h6-font-size: $font-size-base;

$font-sizes: (
    1: $h1-font-size,
    2: $h2-font-size,
    3: $h3-font-size,
    4: $h4-font-size,
    5: $h5-font-size,
    6: $h6-font-size,
    7: 14px,
);

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1070px,
);

$min-contrast-ratio: 3;

$blue: #014d5d;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #d63384;
$red: #d0021b;
$orange: #f5a623;
$yellow: #f8e71c;
$green: #0c8a59;
$teal: #20c997;
$cyan: #79c7c3;

$border-widths: (
    1: 1px,
    2: 2px,
    3: 3px,
    4: 4px,
    5: 5px,
    10: 10px,
);

$navbar-light-color: $body-color;
$input-btn-font-size-lg: 1.15rem;

$input-btn-padding-x: 1rem;
$input-btn-padding-y: 0.75rem;

$input-btn-padding-x-sm: 0.7rem;
$input-btn-padding-y-sm: 0.4rem;

$input-btn-padding-y-lg: 1rem;

$table-cell-padding-y: 0.75rem;
$table-cell-padding-x: 1.5rem;
$table-border-factor: 0.25;

$modal-header-border-color: #fff;

$modal-lg: 760px;
$modal-content-border-radius: 0;

$enable-negative-margins: true;

$breadcrumb-divider: quote('>');

$modal-content-border-width: 0;

$modal-backdrop-bg: #ddd;
$modal-backdrop-opacity: 0.95;

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/maps';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/utilities';
@import 'bootstrap/scss/utilities/api';

$utilities: map-merge(
    $utilities,
    (
        'background-color':
            map-merge(
                map-get($utilities, 'background-color'),
                (
                    responsive: true,
                )
            ),
    )
);

$utilities: map-merge(
    $utilities,
    (
        'border':
            map-merge(
                map-get($utilities, 'border'),
                (
                    responsive: true,
                )
            ),
    )
);

$utilities: map-merge(
    $utilities,
    (
        'border-top':
            map-merge(
                map-get($utilities, 'border-top'),
                (
                    responsive: true,
                )
            ),
    )
);

$utilities: map-merge(
    $utilities,
    (
        'border-bottom':
            map-merge(
                map-get($utilities, 'border-bottom'),
                (
                    responsive: true,
                )
            ),
    )
);

$utilities: map-merge(
    $utilities,
    (
        'border-color':
            map-merge(
                map-get($utilities, 'border-color'),
                (
                    responsive: true,
                )
            ),
    )
);

$utilities: map-merge(
    $utilities,
    (
        'border-width':
            map-merge(
                map-get($utilities, 'border-width'),
                (
                    responsive: true,
                )
            ),
    )
);

$utilities: map-merge(
    $utilities,
    (
        'position':
            map-merge(
                map-get($utilities, 'position'),
                (
                    responsive: true,
                )
            ),
    )
);

$utilities: map-merge(
    $utilities,
    (
        'min-viewport-height':
            map-merge(
                map-get($utilities, 'min-viewport-height'),
                (
                    values: (
                        100: 100vh,
                        72: 72vh,
                        60: 60vh,
                        56: 56vh,
                    ),
                )
            ),
    )
);

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');

@font-face {
    font-family: JoannaMT;
    src: url('./theme/assets/fonts/joanna_mt1.ttf');
}

@import '~bootstrap/scss/bootstrap';

//

[class*='btn-outline-'] {
    background-color: #fff;
}

$theme-colors: (
    'warning-orange': $orange,
);

@each $color, $value in $theme-colors {
    .btn-#{$color} {
        @include button-variant($value, $value);
    }
}

@each $color, $value in $theme-colors {
    .btn-outline-#{$color} {
        @include button-outline-variant($value);
    }
}

//

.text-help {
    color: #d83e00 !important;
}

//

.text-helvetica {
    font-family: Helvetica !important;
}

.text-joanna-mt {
    font-family: JoannaMT !important;
}

//

.fs-1 {
    font-size: $h1-font-size !important;
}

.fs-2 {
    font-size: $h2-font-size !important;
}

@include media-breakpoint-up(md) {
    .fs-md-1 {
        font-size: $h1-font-size !important;
    }

    .fs-md-2 {
        font-size: $h2-font-size !important;
    }
}

//
.d-after-none {
    &::after {
        display: none !important;
    }
}

.fp-container {
    background: #f8f8f8ad;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    overflow-x: hidden;
    transition: 0.5s;
    z-index: 2001 !important;
}

.fp-container .fp-spinner {
    color: $primary;
}

.fp-container .fp-spinner-label {
    color: $primary;
    font-weight: bold;
}

// Admin user table
.filter-text {
    display: block;
    width: 100%;
    padding: 0.75rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #333;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    flex: 0 0 auto;
    width: 25%;
}

.data-table-extensions-filter {
    padding-bottom: 12px !important;
}

.btn-transparent {
    background-color: transparent;
    border: none;
}

.btn-hover:hover {
    border: none !important;
    border-color: transparent !important;
    transform: scale(1.2);
    margin-top: 5px !important;
}

.table-header > .col {
    width: 100%;
    background-color: #f8f9fa;
    min-height: 56px;
    display: flex;
    align-items: center;
    border: solid 1px rgba(0, 0, 0, 0.12);
    justify-content: center;
}

.table-rows > .col {
    min-height: 56px;
    display: flex;
    align-items: center;
    border: solid 1px rgba(0, 0, 0, 0.12);
    justify-content: center;
}
